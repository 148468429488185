import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { NewsLetterBox } from '../components';
import { SiteContext } from '../context';

const Footer = () => {
    const { siteconfig, apiUrl, categories, handleCurrentPage } = useContext(SiteContext);


    const [itemSelected, setItemSelected] = useState({});
    const [showModal, setShowModal] = useState(false);

    const handleShow = (name, url) => {
        let slaccount = {
            name, url
        }
        setItemSelected(slaccount);
        setShowModal(true);
    }
    return (
        <div>
            <ReactModal
                isOpen={showModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        flex: 1,
                        justifyContent: 'center',
                        alignContent: 'center',
                        position: 'fixed',
                        top: '65%',
                        bottom: '10%',
                        left: '5%',
                        right: '5%',
                        width: '90%',
                        height: '25%',
                        backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        right: '10px',
                        bottom: '10px',
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px'
                    }
                }}>
                <div className='row'>
                    <div className='col-10'>
                        <p className='h3 my-1 py-1'>{itemSelected.name}</p>
                        <a href={itemSelected.url} target='_blank' className='my-1 py-2 text-decoration-none'>{itemSelected.url}</a>
                    </div>
                    <div className='col-2'>
                        <span className='btn btn-secondary' onClick={() => setShowModal(false)}>x</span>
                    </div>
                </div>
            </ReactModal>
            <div className='text-dark' style={{ 'backgroundColor': '#eee' }}>
                <div className="container">
                    <div className='row p-2'>
                        <div className='col-md-5 my-1 d-flex justify-content-center align-content-center'>
                            <div className='w-100'>
                                <p className='my-2'>{siteconfig.shopdescription}</p>

                                <NewsLetterBox />


                            </div>
                        </div>
                        <div className='col-md-2 my-1'>
                            <p className='h5'>Links</p>
                            <div style={{ 'width': 30 }} className='border-bottom border-3 border-danger' />
                            <div className='my-2'>
                                <p>
                                    <Link className='text-dark text-decoration-none py-1' onClick={() => handleCurrentPage('services')} to='/services'>Services</Link>
                                </p>
                                <p>
                                    <Link className='text-dark text-decoration-none py-1' onClick={() => handleCurrentPage('photos')} to='/photos'>Photos</Link>
                                </p>
                                <p>
                                    <Link className='text-dark text-decoration-none py-1' onClick={() => handleCurrentPage('blog')} to='/blog'>Blog</Link>
                                </p>
                                <p>
                                    <Link className='text-dark text-decoration-none py-1' onClick={() => handleCurrentPage('about')} to='/page/about'>About</Link>
                                </p>
                            </div>

                        </div>

                        <div className='col-md-2 my-1'>
                            <p className='h5'>Support</p>
                            <div style={{ 'width': 30 }} className='border-bottom border-3 border-danger' />

                            <div className='my-2'>
                                <p>
                                    <Link className='text-dark text-decoration-none py-2' onClick={() => handleCurrentPage('')} to='/page/feedback'>Feedback</Link>
                                </p>
                                <p>
                                    <Link className='text-dark text-decoration-none py-2' onClick={() => handleCurrentPage('contact')} to='/contact'>Contact</Link>
                                </p>
                            </div>
                            {(siteconfig.slfacebook !== '' || siteconfig.sltwitter !== '' || siteconfig.slinstagram !== '' || siteconfig.slyoutube !== '') && <p className='h5 mt-5 my-1'>Follow me on</p>}
                            {siteconfig.slfacebook != '' && <span className='p-1 my-1'><button className='py-1 rounded-circle' onClick={() => handleShow('facebook', siteconfig.slfacebook)}><i className='bi bi-facebook'></i></button></span>}
                            {siteconfig.sltwitter != '' && <span className='p-1 my-1'><button className='py-1 rounded-circle' onClick={() => handleShow('twitter', siteconfig.sltwitter)}><i className='bi bi-twitter'></i></button></span>}
                            {siteconfig.slinstagram != '' && <span className='p-1 my-1'><button className='py-1 rounded-circle' onClick={() => handleShow('instagram', siteconfig.slinstagram)}><i className='bi bi-instagram'></i></button></span>}
                            {siteconfig.slyoutube != '' && <span className='p-1 my-1'><button className='py-1 rounded-circle' onClick={() => handleShow('youtube', siteconfig.slyoutube)}><i className='bi bi-youtube'></i></button></span>}


                        </div>

                        <div className='col-md-3 my-1'>
                            <p className='h5'>Contact</p>
                            <div style={{ 'width': 30 }} className='border-bottom border-3 border-danger' />
                            <div className='my-2'>
                                <span className='p-1'>{siteconfig.shopaddress}</span><br />
                                <span className='p-1'>{siteconfig.shopstate}</span><br /><br />
                                <span className='p-1'><i className='bi bi-envelope'></i>: {siteconfig.shopemailaddress}</span><br />
                            </div>

                        </div>
                    </div>
                </div>
                <div className='bg-dark py-1 text-white small'>
                    <div className='container d-flex justify-content-between'>
                        <div className='py-1 font-weight-bold'>Copyright <Link className='text-white text-decoration-none' to='/'>{siteconfig.shopname}</Link> 2022 | <Link className='text-white text-decoration-none' to='/page/privacy-policy'>Privacy Policy</Link> </div>
                        <div className='py-1 font-weight-bold'>Developed by <a className='text-white text-decoration-none' href='https://justwebservices.com' target={'_blank'}>Just Web Services</a> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;