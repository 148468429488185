import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, PhotoGalleryHome, PopularBlog } from "./";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";
import { removeTags, getHeading } from "../utils";
import moment from "moment";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";
import Seo from "./Seo";

const Home = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    isLoggedOn,
    categories,
    pages,
    aboutme,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const navigate = useNavigate();

  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getArticles();
    handleCurrentPage("home");
  }, []);

  const getArticles = () => {
    fetch(`${apiUrl}/api/articles.php?action=fetchblog&page=1&limit=12`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setStories(res.rows);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const processText = (text) => {
    let thetext = removeTags(text);

    thetext = getHeading(thetext, 25);

    return thetext;
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-content-center my-5">
        <div class="spinner-border text-secondary" role="status" />
      </div>
    );
  }

  return (
    <div>
      <Seo
        title={`Home | ${siteconfig.shopname}`}
        description="Just Web Services provides website development, mobile apps development and web hosting services"
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />

      {aboutme.hasOwnProperty("cpagelinkname") && (
        <div className="px-3 py-5 my-3 text-dark bg-white">
          <div className={isMobile ? "py-1 container" : "py-2 container"}>
            <div className="row">
              <div className="col-md-7">
                <h2>Hello, I am Afam</h2>
                <p>Welcome to my personal space.</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${aboutme.cpagecontent
                      .split(" ", 74)
                      .join(" ")}...`,
                  }}
                />
                More on{" "}
                <Link className="text-decoration-none" to="/page/about">
                  about me
                </Link>
              </div>
              <div
                className={isMobile ? "col-md-5 py-3 text-center" : "col-md-5"}
              >
                <div>
                  <img
                    style={{ height: "auto", width: 300, objectFit: "contain" }}
                    className="rounded-circle"
                    src={`${apiUrl}/${aboutme.cpagephoto}`}
                    width={isMobile ? 200 : 300}
                    height={isMobile ? 200 : 300}
                    alt={aboutme.cpagename}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white my-2">
        <div className="container">
          <div className="my-2 d-flex justify-content-between align-content-center">
            <div>
              <h2 className="px-2">Latest posts</h2>
            </div>
            <div>
              <Link
                className="text-decoration-none btn btn-secondary rounded-pill border"
                to="/blog"
              >
                View all
              </Link>
            </div>
          </div>

          <div className="row">
            {stories.length > 0 &&
              stories.map((item, i) => {
                return (
                  <div key={i} className="col-md-6 p-3">
                    <Link
                      className="text-decoration-none text-dark text-left"
                      to={`/post/${item.titleslug}`}
                      state={{ article: item }}
                    >
                      <div style={{ height: 80 }} className="row">
                        <div className="col-3">
                          <img
                            style={{ height: "70px", objectFit: "cover" }}
                            className="rounded img-fluid"
                            src={`${apiUrl}/${item.artphoto}`}
                            width="100%"
                            height={100}
                            alt={item.title}
                          />
                        </div>
                        <div className="col-9">
                          <p className="h6">{item.title}</p>
                          <span className="text-muted small fst-italic">
                            {moment(item.artpubdate).format("Do MMM YYYY")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="bg-light my-2 py-2">
        <div className="container">
          <div className="my-2 d-flex justify-content-between align-content-center">
            <div>
              <h2 className="px-2">Popular posts</h2>
            </div>
          </div>

          <PopularBlog />
        </div>
      </div>

      <div className="my-3 py-2">
        <PhotoGalleryHome />
      </div>

      <Footer />
    </div>
  );
};

export default Home;
