import React, { useContext, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
  yourname: Yup.string()
    .label('Your name')
    .required()
    .min(3, 'Must have at least 3 characters'),
  youremail: Yup.string()
    .label('Your email address')
    .email('Enter a valid email')
    .required()
});


const NewsLetterBox = () => {

  const { apiUrl, isMobile } = useContext(SiteContext);

  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();

    formData.append('name', values.yourname);
    formData.append('email', values.youremail);
    formData.append('yourchoice', values.yourchoice);
    formData.append('action', 'subscribe');

    return fetch(`${apiUrl}/api/newsletters.php`, {
      method: 'post',
      body: formData
    })
      .then((res) => res.json())
      .then(async (res) => {

        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg('');
          }, 5000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            'Newsletter subscription failed',
            'Error connection to the server please check your details and try again',
            [{ text: 'Okay' }]
          );
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  };


  return (
    <div className='py-3'>
      <h5>Newsletter subscription</h5>
      <div className='my-1 d-flex justify-content-between'>
        <div className='align-items-center'>
          {msg !== '' ? <div className='p-2'><span className='p-2 text-dark'>{msg}</span></div> : null}
          {errorMsg !== '' ? <div className='p-2'><span className='p-2 text-danger'>{errorMsg}</span></div> : null}
        </div>
      </div>

      <Formik
        initialValues={{
          yourname: '',
          youremail: '',
          yourchoice: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, { setSubmitting, resetForm });
        }}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          isValid,
          isSubmitting,
          handleBlur,
          touched,
          setFieldValue
        }) => (
          <div className='px-1'>
            <form onSubmit={handleSubmit} encype="multipart/form-data">
              <div className='rounded my-1'>
                <div className='row rounded my-2'>

                  <div className='col-5 form-group my-1'>
                    <Field
                      id="yourname"
                      name="yourname"
                      value={values.yourname}
                      placeholder="Your name"
                      onBlur={handleBlur('yourname')}
                      autoCapitalize="none"
                      style={{
                        padding: 6,
                      }}
                      className="form-control"
                    />
                    {touched.yourname && errors.yourname && <div className='py-1'>
                      <span className='text-danger'>{touched.yourname && errors.yourname}</span>
                    </div>}
                  </div>

                  <div className='col-5 form-group px-0 mx-0 my-1'>
                    <Field
                      id="youremail"
                      name="youremail"
                      value={values.youremail}
                      placeholder="Your email"
                      onBlur={handleBlur('youremail')}
                      autoCapitalize="none"
                      style={{
                        padding: 6,
                      }}
                      className="form-control"
                    />
                    {touched.youremail && errors.youremail && <div className='py-1'>
                      <span className='text-danger'>{touched.youremail && errors.youremail}</span>
                    </div>}
                  </div>

                  <div className={'col-12 form-group my-1'}>
                    <button
                      type='submit'
                      onClick={handleSubmit}
                      title="Submit"
                      disabled={!isValid || isSubmitting}
                      className='btn btn-primary'
                      style={{
                        padding: 4
                      }}
                    >
                      Submit
                    </button>

                  </div>


                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default NewsLetterBox;