import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import { Cart, SearchBox } from '../components';
import { SiteContext } from "../context/index";

const Header = () => {
  const { isLoggedOn, handleSignOut, getOrderItemsTotal, currentPage, handleCurrentPage, apiUrl, siteconfig, isMobile, singleProduct } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState('');
  const [mType, setMType] = useState('');


  let navigate = useNavigate();

  useEffect(() => {
    if (currentPage !== '') setPage(currentPage);
  }, [currentPage])

  const doSignOut = () => {
    handleSignOut();
  }

  const switchModal = item => {
    if (item === 'menu') {
      setMType('menu');
    } else if (item === 'cart') {
      setMType('cart')
    } else if (item === 'search') {
      setMType('search')
    } else if (item === 'user') {
      setMType('user')
    }
    setShowModal(true);

  }

  const HeaderMenu = () => {
    return <div className='bg-dark'>
      <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' to='/'>Home</Link></div>
      <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' to='/user'>My Account</Link></div>
      <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' to='/services'>Services</Link></div>
      <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' to='/photos'>Photo Gallery</Link></div>
      <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' to='/blog'>Blog</Link></div>
      <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' to='/page/about'>About</Link></div>
      <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' to='/contact'>Contact</Link></div>
      <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' onClick={() => switchModal('search')} to=''>Search</Link></div>
      {isLoggedOn && <div><Link className='p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white' onClick={() => doSignOut()} to=''>Log out</Link></div>}
    </div>
  }

  const UserMenu = () => {
    return <div>
      {userData ? <h4 className='py-1 border-bottom'>Hello {userData.name}</h4> :
        <h4 className='py-1 border-bottom'>Hello Guest</h4>}
      <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/user'>My Account</Link></div>
    </div>
  }

  return (
    <div className='bg-dark'>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            position: 'fixed',
            top: isMobile ? 0 : '25%',
            bottom: isMobile ? 0 : '25%',
            left: isMobile ? 0 : '20%',
            right: isMobile ? 0 : '20%',
            width: isMobile ? '100%' : '60%',
            height: isMobile ? '100%' : '50&',
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '10px',
            left: '10px',
            right: '10px',
            bottom: '10px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '10px'
          }
        }}>
        <div>
          <div className='d-flex justify-content-end'>
            <span className='btn btn-secondary mb-2 py-1' onClick={() => setShowModal(false)}><i className='bi bi-x h3 text-white'></i></span>
          </div>
          {mType === 'menu' && <HeaderMenu />} {mType === 'cart' && <Cart />}  {mType === 'search' && <SearchBox />}  {mType === 'user' && <UserMenu />}
        </div>
      </ReactModal>

      <div style={{ 'height': isMobile ? 130 : 80 }} className='py-2 bg-dark shadow'>
        <div className='container py-1'>
          <div className='row'>
            <div className='col-md-4 col-lg-4 col-8'>
              <div className='d-flex align-content-center justify-content-start'>
                <Link className='text-decoration-none text-white p-1' to='/'>
                  <div className='row bg-dark shadow rounded d-flex align-items-center justify-content-center'>
                    <div className='col-2'>
                      <img className='rounded-circle' src={`${apiUrl}/${siteconfig.shoplogo}`} height={40} width={40} alt='Logo' />
                    </div>
                    <div className={isMobile ? 'col-10 p-1' : 'col-10 p-2'}>
                      <div className='d-none d-md-block h5 my-0 text-center shadow'>AFAM BC NNAJI</div>
                      <div className='d-block d-md-none h6 my-0 px-3 text-left shadow'>AFAM BC NNAJI</div>
                      <div className='px-3 text-muted fst-italic shadow'>my personal space</div>
                    </div>
                  </div>
                </Link>

              </div>
            </div>
            <div className='col-md-8 col-lg-8 col-4 d-flex justify-content-end align-content-center'>
              <div className='col-md-12 d-md-block d-none'>

                <div className='d-flex justify-content-end align-content-center py-1'>
                  <div className='d-none d-md-block'>
                    <Link className={page === 'home' ? 'py-2 px-1 text-decoration-none bg-white text-dark rounded' : 'py-3 px-1 text-decoration-none text-white'} onClick={() => handleCurrentPage('home')} to="/">HOME</Link>
                    <Link className={page === 'about' ? 'py-2 px-2 text-decoration-none bg-white text-dark rounded' : 'py-3 px-2 text-decoration-none text-white'} onClick={() => handleCurrentPage('about')} to="/page/about">ABOUT</Link>
                    <Link className={page === 'services' ? 'py-2 px-1 text-decoration-none bg-white text-dark rounded' : 'py-3 px-1 text-decoration-none text-white'} onClick={() => handleCurrentPage('services')} to="/services">SERVICES</Link>
                    <Link className={page === 'photos' ? 'py-2 px-2 text-decoration-none bg-white text-dark rounded' : 'py-3 px-2 text-decoration-none text-white'} onClick={() => handleCurrentPage('photos')} to="/photos">PHOTOS</Link>
                    <Link className={page === 'blog' ? 'py-2 px-1 text-decoration-none bg-white text-dark rounded' : 'py-3 px-1 text-decoration-none text-white'} onClick={() => handleCurrentPage('blog')} to="/blog">BLOG</Link>
                    <Link className={page === 'contact' ? 'py-2 px-2 text-decoration-none bg-white text-dark rounded' : 'py-3 px-2 text-decoration-none text-white'} onClick={() => handleCurrentPage('contact')} to="/contact">CONTACT</Link>
                    <button className='px-1 text-decoration-none btn btn-transparent text-white' onClick={() => switchModal('search')}><i className='bi bi-search h2'></i></button>

                  </div>
                </div>
              </div>
              <div className='col-md-12 d-block d-md-none'>
                <div className='d-flex justify-content-end align-content-center'>
                  <button className='px-1 d-none text-decoration-none btn btn-transparent text-dark' onClick={() => switchModal('search')}><i className='bi bi-search h2'></i></button>

                  {
                    isLoggedOn &&
                    <div className='py-1'>
                      <span className='px-1'><button className='px-2 py-1 rounded bg-dark text-white' onClick={() => doSignOut()}><i className='bi bi-box-arrow-right h6'></i></button></span>
                    </div>
                  }
                  <span className='px-1'><button className='text-decoration-none btn btn-secondary text-white' onClick={() => switchModal('menu')}><i className='bi bi-list text-white h4'></i></button></span>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='d-blox d-md-none d-flex justify-content-around my-0 py-2 bg-secondary bg-opacity-25'>
          <Link className='btn btn-dark rounded-pill' to='/'><i className='bi bi-house'></i></Link>
          <Link className='btn btn-dark rounded-pill' to='/blog'>Blog</Link>
          <Link className='btn btn-dark rounded-pill' to='/services'>Services</Link>
          <Link className='btn btn-dark rounded-pill' to='/photos'>Gallery</Link></div>
      </div>
    </div>

  )
}

export default Header;