import React from "react";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 4,
  3000: 6,
  2000: 5,
  1200: 3,
  1000: 2,
  500: 1,
};

const MasonryLayout = ({ images, handleItemSelected, apiUrl }) => (
  <Masonry
    className="flex animate-slide-fwd"
    breakpointCols={breakpointColumnsObj}
  >
    {images?.map((item, i) => (
      <div key={i} className="m-2">
        <div
          className=" relative cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out"
          onClick={() => handleItemSelected(i)}
        >
          <img
            className="rounded-lg w-full"
            src={`${apiUrl}/${item.gphoto}`}
            alt={item.gdesc}
          />
        </div>
      </div>
    ))}
  </Masonry>
);

export default MasonryLayout;
