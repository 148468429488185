import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer, ProjectGallery } from './';
import { NavigationBar } from '../components';
import { SiteContext } from '../context/index';
import Seo from './Seo';


const Projects = () => {
    const { apiUrl, pages, siteconfig, userData } = useContext(SiteContext);

    return (
        <div>
            <Seo
                title={`Projects | Just Web Services`}
                description="Some of the projects we have carried out web development, mobile apps development, react, react native, iOS, Android, and custom software development"
                url={`${apiUrl}/projects`}
                img={`${apiUrl}/${siteconfig.shoplogo}`}
            />

            <Header />
            <div className='container'>

                <NavigationBar section='Projects' goback={false} />

                <ProjectGallery />

                <Footer />

            </div>
        </div>
    )
}

export default Projects;