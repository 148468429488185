import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { formatViews, getHeading } from "../utils";
import { SiteContext } from "../context";
import { MotionWrap, classNames } from "../components";

const PopularBlog = () => {
  const { apiUrl } = useContext(SiteContext);

  const [popularArticles, setPopularArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    doPopular();
  }, []);

  const doPopular = () => {
    setLoading(true);

    return fetch(`${apiUrl}/api/articles.php?action=fetchpopular`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setPopularArticles(res.rows);
          setLoading(false);
        } else if (res.status === 400) {
          console.log(res.msg);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {loading && (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div class="spinner-border text-secondary" role="status" />
        </div>
      )}

      <div className="row">
        {popularArticles.length > 0 &&
          popularArticles.map((populararticle, i) => {
            return (
              <div key={i} className="col-md-6 col-lg-4 p-3 bg-light">
                <Link
                  className="text-decoration-none text-dark text-left"
                  to={`/post/${populararticle.titleslug}`}
                  state={{ article: populararticle }}
                >
                  <div style={{ height: 100 }} className="row shadow">
                    <div className="col-3">
                      <img
                        style={{ height: "90px", objectFit: "cover" }}
                        className="rounded img-fluid"
                        src={`${apiUrl}/${populararticle.artphoto}`}
                        width="100%"
                        height={100}
                        alt={populararticle.title}
                      />
                    </div>
                    <div className="col-9">
                      <p className="h6">
                        {getHeading(populararticle.title, 10)}
                      </p>
                      <span className="text-muted small fst-italic">
                        {formatViews(populararticle.views)}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MotionWrap(PopularBlog, classNames);
