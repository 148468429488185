import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { DashboardMenu, NavigationBar } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const validationSchema = Yup.object().shape({
  cat: Yup.string()
    .label('Category')
    .required()
});

const PhotoGalleryUpdate = () => {


  let photo = useLocation().state.photo;
  let navigate = useNavigate();

  const { apiUrl, slugify } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [photoCat, setPhotoCat] = useState([]);
  const [story, setStory] = useState('');
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setStory(photo.gdesc);
  }, [])

  useEffect(() => {
    getPhotoCat();
  }, [])

  const getPhotoCat = () => {
    fetch(`${apiUrl}/api/photogallery.php?action=fetchcat&role=${userData.role}`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {
      if (res.status === 200) {
        if (res.categories.length > 0) {
          setPhotoCat(res.categories);
        }
      } else if (res.status === 400) {
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        }, 3000)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    if (story === '' || story === undefined) {
      alert('Please provide the content for the caption/description.');
      setSubmitting(false);
      return;
    }

    let formData = new FormData();

    formData.append('type', values.type);
    formData.append('catid', values.cat);
    formData.append('desc', story);
    formData.append('photo', values.photo);
    formData.append('showonhome', values.showonhome ? 1 : 0);
    formData.append('photoid', values.photoid);
    formData.append('user', userData.username);
    formData.append('role', userData.role);
    formData.append('action', 'update');

    return fetch(`${apiUrl}/api/photogallery.php`, {
      method: 'post',
      body: formData
    })
      .then((res) => res.json())
      .then(async (res) => {

        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg('');
          }, 3000);
          setTimeout(() => {
            navigate('/adminphotogallery');
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            'Page submission failed',
            'Error connection to the server please check your details and try again',
            [{ text: 'Okay' }]
          );
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  };


  return (
    <div>
      <Header />
      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>

              <NavigationBar section={'Photo Gallery'} goback={true} />

              <h3>Photo Manage</h3>
              <div className='my-2 d-flex justify-content-between'>
                <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                  {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  type: photo.gtype != '' ? photo.gtype : '',
                  photoid: photo.galleryid != '' ? photo.galleryid : '',
                  cat: photo.gcatid != '' ? photo.gcatid : '',
                  desc: photo.gdesc != '' ? photo.gdesc : '',
                  photo: photo.gphoto != '' ? photo.gphoto : '',
                  showonhome: photo.showonhome == 1 ? true : false,
                  user: photo.gusername != '' ? photo.gusername : userData.username,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue
                }) => (
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className='container my-2'>

                      <div className='form-group my-1'>
                        <div>
                          <label htmlFor="type">Type</label>
                        </div>
                        <Field
                          as="select"
                          id="type"
                          name="type"
                          value={values.type}
                          onBlur={handleBlur('type')}
                          autoCapitalize="none"
                          style={{
                            padding: 10
                          }}
                          className="form-control"
                        >
                          <option value="">Select...</option>
                          <option value="Photo Gallery">Photo Gallery</option>
                          <option value="Project Gallery">Project Gallery</option>
                        </Field>

                        {touched.type && errors.type && <div className='py-1'>
                          <span className='text-danger'>{touched.type && errors.type}</span>
                        </div>}
                      </div>


                      <div className='form-group my-1'>
                        <div>
                          <label htmlFor="cat">Category</label>
                        </div>
                        <Field
                          as="select"
                          id="cat"
                          name="cat"
                          value={values.cat}
                          placeholder="Category"
                          onBlur={handleBlur('cat')}
                          autoCapitalize="none"
                          style={{
                            padding: 4
                          }}
                          className="form-control"
                        >
                          <option value="">Select...</option>
                          {photoCat && photoCat.map((item, i) => {
                            return (
                              <option key={i} value={item.gallcatid}>{item.gallcatname}</option>
                            )
                          })}
                        </Field>

                        {touched.cat && errors.cat && <div className='py-1'>
                          <span className='text-danger'>{touched.cat && errors.cat}</span>
                        </div>}
                      </div>

                      <div className='form-group my-3'>
                        <div>
                          <label htmlFor="photo"> Upload picture</label>
                        </div>
                        <input id="photo" name="photo" type="file" onChange={(event) => {
                          setFieldValue("photo", event.currentTarget.files[0]);
                        }} />
                        <img className='my-2' src={`${apiUrl}/${photo.gphoto}`} class="img-fluid mt-2" width={200} height={100} />
                      </div>

                      <div className='form-group my-1'>
                        <div>
                          <label htmlFor="desc">{values.type === 'Project Gallery' ? 'Project description' : 'Picture caption'}</label>
                        </div>
                        <ReactQuill theme="snow" value={story} onChange={setStory} />
                      </div>

                      <div className='form-group my-1 py-2'>
                        <div>
                          <label htmlFor="showonhome">Show on home page</label>
                          <Field
                            className="mx-2"
                            type="checkbox"
                            name="showonhome"
                          /> {values.showonhome ? 'Yes' : 'No'}
                        </div>
                      </div>

                    </div>
                    <button
                      type='submit'
                      onClick={handleSubmit}
                      title="Submit"
                      disabled={!isValid || isSubmitting}
                      className='btn btn-primary'
                    >
                      Submit
                    </button>

                    <button
                      onClick={() => navigate(-1)}
                      title="Cancel"
                      className='btn btn-secondary mx-2'
                    >
                      Cancel
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PhotoGalleryUpdate;