import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { MasonryLayout, NavigationBar } from "../components";
import { SiteContext } from "../context/index";
import Seo from "./Seo";
import ReactModal from "react-modal";

const Photos = () => {
  const { userData, apiUrl, siteconfig, isLoggedOn, categories, isMobile } =
    useContext(SiteContext);

  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [photos, setPhotos] = useState([]);
  const [photoCat, setPhotoCat] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [catSelected, setCatSelected] = useState("all");
  const [itemSelected, setItemSelected] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [selectedCat, setSelectedCat] = useState("All");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getAllPhotos();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const doSelected = (cat) => {
    if (cat === "all") {
      setFilteredPhotos(photos);
      setCurrentIndex(0);
    } else {
      let allphotos = photos.slice();
      let filteredCat = allphotos.filter((item) => item.gcatid === cat);

      setSelectedCat(cat);
      setFilteredPhotos(filteredCat);
      setCurrentIndex(0);
    }
  };

  const getAllPhotos = () => {
    return fetch(
      `${apiUrl}/api/photogallery.php?action=fetch&type=Photo Gallery`,
      {
        method: "get",
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          setMsg(res.msg);
          setPhotos(res.photos);
          setFilteredPhotos(res.photos);
          setPhotoCat(res.categories);
          setLoading(false);
          setTimeout(() => {
            setMsg("");
          }, 2000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setLoading(false);
        } else {
          alert(
            "Operation failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const doPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(parseInt(currentIndex - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCurrentIndex(currentIndex);
      setCangoBack(false);
      setCangoForward(true);
    }
    handleItemSelected(currentIndex);
  };

  const doNext = () => {
    if (currentIndex < filteredPhotos.length - 1) {
      setCurrentIndex(parseInt(currentIndex + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCurrentIndex(currentIndex);
      setCangoForward(false);
      setCangoBack(true);
    }
    handleItemSelected(currentIndex);
  };

  const handleItemSelected = (id, type) => {
    if (type === "pic") {
      if (id === 0) {
        setCurrentIndex(0);
        setCangoBack(true);
        setCangoForward(true);
      } else if (id > 0) {
        setCurrentIndex(id);
        setCangoBack(false);
        setCangoForward(true);
      } else if (currentIndex < filteredPhotos.length - 1) {
        setCurrentIndex((currentIndex) => currentIndex + 1);
        setCangoForward(true);
        setCangoBack(true);
      } else if (currentIndex === filteredPhotos.length - 1) {
        setCurrentIndex(currentIndex);
        setCangoForward(false);
        setCangoBack(true);
      }
    }

    let item = filteredPhotos.filter((item, i) => i === id);
    setItemSelected(item[0]);
    setShowModal(true);
  };

  return (
    <div
      style={{ backgroundColor: "white" }}
      className={showModal ? "w-100 h-100 bg-dark bg-opacity-80 relative" : ""}
    >
      {/* {showModal && <div style={{'position':'fixed', 'top':0, 'left':0, 'zIndex':150}} className='w-100 h-100 bg-dark bg-opacity-75' />} */}

      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? 0 : 0,
            bottom: isMobile ? 0 : 0,
            left: isMobile ? 0 : "20%",
            right: isMobile ? 0 : "20%",
            width: isMobile ? "100%" : "60%",
            height: isMobile ? "100%" : "600px",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            zIndex: 200,
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "10px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-between align-center-center bg-light">
            <div className="row">
              <div className="col text-center">
                {cangoBack ? (
                  <span
                    className="h6 btn bg-dark text-white border-right py-1 px-2"
                    onClick={() => doPrevious()}
                  >
                    {" "}
                    <i className="bi bi-chevron-left"></i>
                  </span>
                ) : (
                  <span className="h6 btn bg-secondary text-white border-right py-1 px-2">
                    {" "}
                    <i className="bi bi-chevron-left"></i>
                  </span>
                )}
              </div>
              <div className="col text-center">
                {cangoForward ? (
                  <span
                    className="h6 btn bg-dark text-white border-left py-1 px-2"
                    onClick={() => doNext()}
                  >
                    {" "}
                    <i className="bi bi-chevron-right"></i>
                  </span>
                ) : (
                  <span className="h6 btn bg-secondary text-white border-left py-1 px-2">
                    {" "}
                    <i className="bi bi-chevron-right"></i>
                  </span>
                )}
              </div>
            </div>
            <div
              className="btn btn-transparent"
              onClick={() => setShowModal(false)}
            >
              <span className="text-dark">Close</span>
              <span className="h4 text-danger px-2">X</span>
            </div>
          </div>
          <div style={{ height: "500px" }} className="bg-secondary text-center">
            <img
              style={{ objectFit: "contain", height: "450px", width: "100%" }}
              src={`${apiUrl}/${itemSelected.gphoto}`}
              width="100%"
              alt={itemSelected.gdesc}
            />
            <div
              style={{
                bottom: "10px",
                padding: "10px",
                backgroundColor: "#000",
                color: "#fff",
              }}
              className="my-2 py-2 text-start"
              dangerouslySetInnerHTML={{ __html: itemSelected.gdesc }}
            />
          </div>
        </div>
      </ReactModal>
      {showModal && (
        <div
          style={{ position: "fixed", top: 0, left: 0, zIndex: 150 }}
          className="w-100 h-100 bg-dark bg-opacity-75"
        ></div>
      )}
      <Seo
        title={`Photo Gallery | ${siteconfig.shopname}`}
        description="Some of the pictures from our photo gallery"
        url={`${apiUrl}/photo-gallery`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      <Header />
      <div className="container">
        <NavigationBar section="Photo Gallery" goback={false} />

        <div className={showModal ? "bg-secondary bg-opacity-50" : ""}>
          <div className="container">
            <h2 className="py-3">Photo gallery</h2>

            <div className="my-2 d-flex justify-content-between">
              <div className="align-items-center">
                {msg !== "" ? (
                  <div className="p-2">
                    <span className="p-2 text-dark">{msg}</span>
                  </div>
                ) : null}
                {errorMsg !== "" ? (
                  <div className="p-2">
                    <span className="p-2 text-dark">{errorMsg}</span>
                  </div>
                ) : null}
              </div>
            </div>

            {photoCat && (
              <div className="p-1">
                <button
                  className={
                    catSelected === "all"
                      ? "m-2 p-2 border-0 h5"
                      : "m-2 p-2 border-0"
                  }
                  onClick={() => {
                    doSelected("all");
                    setCatSelected("all");
                  }}
                >
                  All
                </button>
                {photoCat.map((item, i) => {
                  return (
                    <button
                      key={i}
                      className={
                        catSelected === item.gallcatname
                          ? "m-2 p-2 border-0 h5"
                          : "m-2 p-2 border-0"
                      }
                      onClick={() => {
                        doSelected(item.gallcatid);
                        setCatSelected(item.gallcatname);
                      }}
                    >
                      {item.gallcatname}
                    </button>
                  );
                })}
              </div>
            )}

            <div className="py-2">
              {!loading && filteredPhotos ? (
                //     filteredPhotos && filteredPhotos.map((item, i) => {
                // return <div key={i} className='col-md-2 col-6'>
                //   <div className='p-2 my-2'>
                //     <button className='rounded-3 border-0' onClick={() => handleItemSelected(i,'pic')}><img style={{ 'width': '100%', 'height': 'auto', 'objectFit': 'contain' }} src={`${apiUrl}/${item.gphoto}`} alt={item.gdesc} height={(parseInt(item.gcatid) === 9 || parseInt(item.gcatid) === 10) ? 100 : 130} width={'100%'} /></button>
                //   </div>
                // </div>
                //     })
                <MasonryLayout
                  images={filteredPhotos}
                  handleItemSelected={handleItemSelected}
                  apiUrl={apiUrl}
                />
              ) : (
                <div className="d-flex justify-content-center align-content-center my-5">
                  <div class="spinner-border text-secondary" role="status" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Photos;
